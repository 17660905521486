
  import { defineComponent } from 'vue';
  import { Button } from '@/components'
  import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
  import store from '@/store'
  import Swal from 'sweetalert2'
  export default defineComponent({
    name : '2FAPage',
    components:{
      SuiteInput,
      Button
    },
    data(){
      return{
        code2fa:"",
        error: ""
      }
    },
    methods:{
      sendCode(){
        store.dispatch('send2FACode',{code2fa:this.code2fa})
        .then((data) => {
            store.dispatch('removeCookieNeeds2FA')
            this.$router.push('/dashboard')
        })
        .catch((error) => {
          this.error = this.$t('mobile_verification_error')
        })
      },
      async resendCode(){
        await store.dispatch('resend2FACode')
        .then((data) => {
          Swal.fire({
              title:this.$t('mobile_verification_resent'),
              icon:"success",
            })
        })
      },
    }
  })
  